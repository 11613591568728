<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <validation-provider v-slot="{ errors }" name="Staff ID" rules="required|numeric">
                    <v-text-field v-model="localItem.staff_id" :error-messages="errors"
                                  :label="$vuetify.lang.t('$vuetify.pages.staff.staffId')"
                                  :min="0" type="number"/>
                </validation-provider>
            </v-col>
            <v-col cols="12" sm="6">
                <v-row justify="end">
                    <v-switch
                        v-model="localItem.enabled"
                        :label="localItem.enabled ? 'Enabled': 'Disabled'"
                    ></v-switch>
                </v-row>
            </v-col>
            <v-col cols="12" sm="6">
                <validation-provider v-slot="{ errors }" name="First Name" rules="required|min:2|max:50">
                    <v-text-field v-model="localItem.first_name" :error-messages="errors"
                                  :label="$vuetify.lang.t('$vuetify.pages.staff.firstName')">
                    </v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="12" sm="6">
                <validation-provider v-slot="{ errors }" name="Last Name" rules="required|min:2|max:50">
                    <v-text-field v-model="localItem.last_name" :error-messages="errors"
                                  :label="$vuetify.lang.t('$vuetify.pages.staff.lastName')"></v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="12" sm="6">
                <validation-provider v-slot="{ errors }" name="Email" rules="required|email">
                    <v-text-field v-model="localItem.email" :error-messages="errors"
                                  :label="$vuetify.lang.t('$vuetify.pages.staff.email')"></v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="12" sm="6">
                <validation-provider v-slot="{ errors }" name="Phone Number" rules="required|kuwait_phone">
                    <v-text-field v-model="localItem.phone_number" :error-messages="errors"
                                  placeholder="+965"
                                  :label="$vuetify.lang.t('$vuetify.pages.staff.phoneNumber')"></v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="12">
                <kurcc-autocomplete
                    :display-function="displayMethod"
                    :items="roles"
                    :loading="fetchingRoles"
                    :multiple="false"
                    :name="$vuetify.lang.t('$vuetify.pages.staff.role')"
                    :selected-items.sync="localItem.role"
                ></kurcc-autocomplete>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "KurccStaffDialog",
    props: {
        item: {
            required: true
        }
    },
    components: {
        KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete')
    },
    data() {
        return {
            roles: [],
            fetchingRoles: false
        }
    },
    computed: {
        localItem: {
            get() {
                return this.item;
            },
            set(v) {
                this.$emit('input', v)
            }
        },
    },
    methods: {
        displayMethod(data) {
            return data.item.display_name
        }
    },
    created() {
        this.fetchingRoles = true
        this.$store.dispatch('getAllRoles', {withIncludes: false}).then(res => {
            this.roles = res.items
        }).finally(() => {
            this.fetchingRoles = false
        })
    }
}
</script>
