var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Staff ID","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.staff.staffId'),"min":0,"type":"number"},model:{value:(_vm.localItem.staff_id),callback:function ($$v) {_vm.$set(_vm.localItem, "staff_id", $$v)},expression:"localItem.staff_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-switch',{attrs:{"label":_vm.localItem.enabled ? 'Enabled': 'Disabled'},model:{value:(_vm.localItem.enabled),callback:function ($$v) {_vm.$set(_vm.localItem, "enabled", $$v)},expression:"localItem.enabled"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required|min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.staff.firstName')},model:{value:(_vm.localItem.first_name),callback:function ($$v) {_vm.$set(_vm.localItem, "first_name", $$v)},expression:"localItem.first_name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.staff.lastName')},model:{value:(_vm.localItem.last_name),callback:function ($$v) {_vm.$set(_vm.localItem, "last_name", $$v)},expression:"localItem.last_name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.staff.email')},model:{value:(_vm.localItem.email),callback:function ($$v) {_vm.$set(_vm.localItem, "email", $$v)},expression:"localItem.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required|kuwait_phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"placeholder":"+965","label":_vm.$vuetify.lang.t('$vuetify.pages.staff.phoneNumber')},model:{value:(_vm.localItem.phone_number),callback:function ($$v) {_vm.$set(_vm.localItem, "phone_number", $$v)},expression:"localItem.phone_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('kurcc-autocomplete',{attrs:{"display-function":_vm.displayMethod,"items":_vm.roles,"loading":_vm.fetchingRoles,"multiple":false,"name":_vm.$vuetify.lang.t('$vuetify.pages.staff.role'),"selected-items":_vm.localItem.role},on:{"update:selectedItems":function($event){return _vm.$set(_vm.localItem, "role", $event)},"update:selected-items":function($event){return _vm.$set(_vm.localItem, "role", $event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }